import { Tabs, Tab, Box } from '@mui/material';
import { ParseValProps } from './types';

interface SubTabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const SubTabPanel: React.FC<SubTabPanelProps> = ({
	children,
	value,
	index
}) => (
	<div role="tabpanel" hidden={value !== index}>
		{value === index && <Box sx={{ p: 2 }}>{children}</Box>}
	</div>
);

interface ConfigSubTabsProps {
	configKey: string;
	value: object;
	activeSubTab: { [key: string]: number };
	onSubTabChange: (newValue: number) => void;
	ParseValComponent: React.FC<ParseValProps>;
}

export const ConfigSubTabs: React.FC<ConfigSubTabsProps> = ({
	configKey,
	value,
	activeSubTab,
	onSubTabChange,
	ParseValComponent
}) => {
	const subEntries = Object.entries(value).filter(
		([_, v]) => typeof v === 'object' && v !== null && !Array.isArray(v)
	);

	if (subEntries.length === 0) {
		return (
			<ParseValComponent key={configKey} name={configKey} val={value} />
		);
	}

	return (
		<>
			<Tabs
				value={activeSubTab[configKey] || 0}
				onChange={(_, newValue) => onSubTabChange(newValue)}
				variant="scrollable"
				scrollButtons="auto"
				sx={{
					minHeight: '36px',
					'& .MuiTab-root': {
						minHeight: '36px',
						padding: '6px 12px'
					}
				}}
			>
				{subEntries.map(([subKey]) => (
					<Tab key={subKey} label={subKey} />
				))}
			</Tabs>
			{subEntries.map(([subKey, subVal], subIndex) => (
				<SubTabPanel
					key={subKey}
					value={activeSubTab[configKey] || 0}
					index={subIndex}
				>
					<ParseValComponent
						key={subKey}
						name={subKey}
						val={subVal}
					/>
				</SubTabPanel>
			))}
		</>
	);
};
