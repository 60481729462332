import {
	CreateButton,
	Datagrid,
	ExportButton,
	FilterButton,
	List,
	Pagination,
	ReferenceArrayField,
	SelectColumnsButton,
	SimpleShowLayout,
	TextField,
	TextInput,
	TopToolbar
} from 'react-admin';
import { Typography } from '@mui/material';

import {
	ClientAuthDatagrid,
	clientAuthDatagridDefaultProps
} from '../ClientAuth/components';
import { useHasPermission } from '../../lib';

const ExpandClientGroup: React.FC = () => (
	<SimpleShowLayout>
		<ReferenceArrayField
			label={false}
			reference="ClientAuth"
			source="clients_ids"
			perPage={10}
			pagination={<Pagination />}
		>
			<TopToolbar>
				<Typography
					component="div"
					variant="subtitle1"
					marginRight="auto"
				>
					Clients
				</Typography>

				<SelectColumnsButton />
			</TopToolbar>

			<ClientAuthDatagrid
				omit={[
					...clientAuthDatagridDefaultProps.omit,
					'isConnected',
					'last_auth',
					'status',
					'os_active',
					'should_auto_update'
				]}
			/>
		</ReferenceArrayField>
	</SimpleShowLayout>
);

const ClientGroupListActions: React.FC = () => {
	const { hasPermission } = useHasPermission();
	return (
		<TopToolbar>
			<FilterButton />
			{hasPermission('Create Client Group') && <CreateButton />}
			{hasPermission('Export Client Group') && <ExportButton />}
		</TopToolbar>
	);
};
export const ClientGroupList: React.FC = () => (
	<List
		filters={[
			<TextInput
				label="Search Client Group"
				source="clientGroup_search"
				alwaysOn
			/>
		]}
		actions={<ClientGroupListActions />}
	>
		<Datagrid rowClick="edit" expand={<ExpandClientGroup />}>
			<TextField source="id" />
			<TextField source="name" />
			<TextField source="description" />

			<ReferenceArrayField
				reference="FeatureFlag"
				source="features_ids"
			/>
		</Datagrid>
	</List>
);
