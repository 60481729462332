import { useEffect, useMemo, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { Labeled } from 'ra-ui-materialui';
import {
	Grid,
	Typography,
	Card,
	CardHeader,
	CardContent,
	Tabs,
	Tab,
	Box
} from '@mui/material';

import { Schema } from '../../../types';
import { ConfigSubTabs } from './ConfigSubTabs';

type NestedConfigProps = {
	name: string;
	val: object;
};

const NestedConfig: React.FC<NestedConfigProps> = ({ name, val }) => {
	const entries = useMemo(() => Object.entries(val), [val]);

	return (
		<Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
			<Card sx={{ p: 1 }}>
				<CardHeader subheader={name} sx={{ p: 0, maxWidth: '100%' }} />

				<CardContent
					sx={{
						'&.MuiCardContent-root': { p: 0, maxWidth: '100%' },
						maxWidth: '100%'
					}}
				>
					<Grid
						rowSpacing={1}
						columnSpacing={5}
						container
						p={0}
						sx={{ maxWidth: '100%' }}
					>
						{entries.map(([key, val]) => (
							<ParseVal name={`${name} > ${key}`} val={val} />
						))}
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

type StringValProps = {
	name: string;
	val: string;
};

const StringVal: React.FC<StringValProps> = ({ name, val }) => (
	<Grid item xs="auto" sx={{ maxWidth: '100% !important' }}>
		<Labeled label={name} component="div" sx={{ maxWidth: '100%' }}>
			<Typography
				variant="body2"
				component="div"
				sx={{
					maxWidth: '100%',
					fontFamily: 'monospace',
					fontSize: '1rem'
				}}
			>
				{val}
			</Typography>
		</Labeled>
	</Grid>
);

type ParseValProps = {
	name: string;
	val: unknown;
};

const ParseVal: React.FC<ParseValProps> = ({ name, val }) => {
	switch (typeof val) {
		case 'function':
		case 'bigint':
		case 'symbol':
		case 'boolean':
		case 'number':
		case 'undefined':
			return <StringVal name={name} val={String(val)} />;
		case 'object':
			if (val === null) return <StringVal name={name} val="null" />;

			return <NestedConfig key={name} name={name} val={val} />;
		case 'string':
			try {
				const parsedVal = JSON.parse(val);

				if (typeof parsedVal !== 'object')
					return <StringVal name={name} val={val} />;
				if (parsedVal === null)
					return <StringVal name={name} val={val} />;

				return <NestedConfig key={name} name={name} val={parsedVal} />;
			} catch (err) {
				return <StringVal name={name} val={val} />;
			}
	}
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
	<div role="tabpanel" hidden={value !== index}>
		{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
	</div>
);

export const ClientAuthConfigTab: React.FC = () => {
	const record = useRecordContext() as Schema.ClientAuth | undefined;
	const [config, setConfig] = useState<[string, unknown][]>();
	const [error, setError] = useState<string>();
	const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState(0);
	const [activeSubTab, setActiveSubTab] = useState<{ [key: string]: number }>(
		{}
	);

	useEffect(() => {
		if (!record) return;

		(async () => {
			try {
				const res = await fetch(
					`/secure/getconfig?client_identifier=${record.client_identifier}`
				);
				setLoading(false);

				if (res.status === 400) {
					return setError('Client not connected.');
				}

				if (!res.ok)
					return setError(
						`Error: ${res.status} ${
							res.statusText
						}: ${await res.text()}`
					);

				const rawConfig = await res.json();

				setConfig(Object.entries(rawConfig));
			} catch (err) {
				setError(String(err));
			}
		})();
	}, [record]);

	const { rootObjects, normalEntries } = useMemo(() => {
		if (!config) return { rootObjects: [], normalEntries: [] };

		return config.reduce(
			(acc, [key, val]) => {
				if (
					typeof val === 'object' &&
					val !== null &&
					!Array.isArray(val)
				) {
					acc.rootObjects.push([key, val]);
				} else {
					acc.normalEntries.push([key, val]);
				}
				return acc;
			},
			{
				rootObjects: [] as [string, object][],
				normalEntries: [] as [string, unknown][]
			}
		);
	}, [config]);

	if (error) return <>{error}</>;
	if (loading || config === undefined) return <>Loading...</>;

	return (
		<Box sx={{ width: '100%' }}>
			{rootObjects.length > 0 && (
				<>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							value={activeTab}
							onChange={(_, newValue) => setActiveTab(newValue)}
							variant="scrollable"
							scrollButtons="auto"
							sx={{
								minHeight: '36px',
								'& .MuiTab-root': {
									minHeight: '36px',
									padding: '6px 12px'
								}
							}}
						>
							{normalEntries.length > 0 && (
								<Tab label="General" />
							)}
							{rootObjects.map(([key]) => (
								<Tab key={key} label={key} />
							))}
						</Tabs>
					</Box>

					{normalEntries.length > 0 && (
						<TabPanel value={activeTab} index={0}>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={5}
								sx={{ maxWidth: '100%' }}
							>
								{normalEntries.map(([key, val]) => (
									<ParseVal key={key} name={key} val={val} />
								))}
							</Grid>
						</TabPanel>
					)}

					{rootObjects.map(([key, val], index) => (
						<TabPanel
							key={key}
							value={activeTab}
							index={normalEntries.length > 0 ? index + 1 : index}
						>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={5}
								sx={{ maxWidth: '100%' }}
							>
								{key === 'webform' || key === 'templates' ? (
									<ConfigSubTabs
										configKey={key}
										value={val}
										activeSubTab={activeSubTab}
										onSubTabChange={(newValue: number) =>
											setActiveSubTab(prev => ({
												...prev,
												[key]: newValue
											}))
										}
										ParseValComponent={ParseVal}
									/>
								) : (
									<ParseVal key={key} name={key} val={val} />
								)}
							</Grid>
						</TabPanel>
					))}
				</>
			)}

			{rootObjects.length === 0 && (
				<Grid
					container
					rowSpacing={1}
					columnSpacing={5}
					sx={{ maxWidth: '100%' }}
				>
					{config.map(([key, val]) => (
						<ParseVal key={key} name={key} val={val} />
					))}
				</Grid>
			)}
		</Box>
	);
};
