import { usePermissions } from 'react-admin';
import { useCallback, useMemo } from 'react';

type UseHasPermission = Omit<
	ReturnType<typeof usePermissions>,
	'permissions'
> & {
	hasPermission: (permission: string) => boolean;
	permissions: string[] | undefined;
};

export const useHasPermission = (): UseHasPermission => {
	const { permissions, ...rest } = usePermissions<string[]>();

	const hasAdminPermission = useMemo(
		() => permissions?.includes('admin'),
		[permissions]
	);

	const hasPermission = useCallback(
		(permission: string) => {
			if (hasAdminPermission) return true;

			return permissions?.includes(permission);
		},
		[permissions, hasAdminPermission]
	);

	return {
		hasPermission,
		permissions,
		...rest
	};
};
