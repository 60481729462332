import React, { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { useHasPermission } from '../lib';

export type RequiresPermissionsProps = React.PropsWithChildren & {
	requiredPermissions: string[];
	fallback?: JSX.Element;
};

export const RequiresPermissions: React.FC<RequiresPermissionsProps> = ({
	requiredPermissions,
	fallback,
	children
}) => {
	const { hasPermission, isLoading } = useHasPermission();
	const hasPermissions = useMemo(
		() => requiredPermissions.every(hasPermission),
		[hasPermission, requiredPermissions]
	);

	if (isLoading) return <CircularProgress />;

	return <>{hasPermissions ? children : fallback}</>;
};
