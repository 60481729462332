import { AuthProvider } from 'react-admin';

const { REACT_APP_DISABLE_AUTH: DISABLE_AUTH } = process.env;
const loginRedirectUrl = `${window.location.origin}/login`;
const checkAuthUrl = '/secure/checkauth';

const loginUrlParams = new URLSearchParams({
	successUrl: `${window.location.origin}/dashboard`,
	failureUrl: loginRedirectUrl
});
const logoutUrlParams = new URLSearchParams({
	loginUrl: loginRedirectUrl
});

const loginUrl = `/secure/google?${loginUrlParams.toString()}`;
const logoutUrl = `/secure/logout?${logoutUrlParams.toString()}`;

class NotAuthenticatedError extends Error {
	constructor(message: string) {
		super(`Not authenticated. Error: ${message}`);
	}
}

const isAuthenticated = async () => {
	const res = await fetch(checkAuthUrl, {
		credentials: 'include'
	});

	if (!res.ok)
		throw new NotAuthenticatedError(`${res.status}: ${res.statusText}`);

	const authResult = await res.json();
	localStorage.setItem('permissions', JSON.stringify(authResult.permissions));

	if (authResult._json.email_verified !== true) {
		throw new NotAuthenticatedError(authResult);
	}
};

export const authProvider: AuthProvider = {
	async login() {
		if (DISABLE_AUTH === 'true') return;

		window.location.href = loginUrl;
	},
	async logout() {
		if (DISABLE_AUTH === 'true') return;
		localStorage.removeItem('permissions');
		window.location.href = logoutUrl;
	},
	async checkAuth() {
		if (DISABLE_AUTH === 'true') return;

		try {
			await isAuthenticated();
		} catch (err) {
			console.error('checkAuth failed with error', err);

			window.location.href = loginUrl;
		}
	},
	async checkError() {
		return;
	},
	async getPermissions() {
		if (DISABLE_AUTH === 'true') return ['admin'];
		await authProvider.checkAuth({});
		const permissions = JSON.parse(
			localStorage.getItem('permissions') || '[]'
		);
		return permissions;
	}
};
